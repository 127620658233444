import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Dropdown, DropdownButton, Form, Row, Spinner } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale/ru";
import cn from "classnames";

import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { PollTag as PollTagModel } from "../../../services/models/poll_tag";
import { Sector as SectorModel } from "../../../services/models/sector";
import { SubUser as SubUserModel } from "../../../services/models/sub_user";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as PollTagApi from "../../../services/network/poll_tag-api";
import * as SectorApi from "../../../services/network/sector-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import createPolls from "../../../Utils/createPolls";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";

registerLocale("ru", ru);

interface CreateUpdatePollTagPageViewProps {
  pollTemplateId: string;
  pollTagId?: string;
  createNew: boolean;
}

const CreateUpdatePollTagPageView = ({ pollTemplateId, pollTagId, createNew }: CreateUpdatePollTagPageViewProps) => {
  const [pollTag, setPollTag] = useState<PollTagModel>({
    id: pollTagId || "",
    poll_template: pollTemplateId,
    date: new Date().toDateString(),
  });

  const getDateType = (tag: PollTagModel) => (tag.dateEnd ? "range" : "month");
  const [dateType, setDateType] = useState<"month" | "range">(getDateType(pollTag));

  const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>();
  const [subUsers, setSubUsers] = useState<SubUserModel[]>([]);
  const [sectors, setSectors] = useState<SectorModel[]>([]);
  const [sectorsToShow, setSectorsToShow] = useState<SectorModel[]>([]);
  const [sectorIdsToUse, setSectorIdsToUse] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showDataLoadingError, setShowDataLoadingError] = useState(false);

  const [sectorNameFilter, setSectorsNameFilter] = useState("");

  useEffect(() => {
    async function loadData() {
      if (!createNew && pollTagId) {
        try {
          setShowDataLoadingError(false);
          setDataLoading(true);
          const pollTagResponse = await PollTagApi.fetchPollTag(pollTagId);
          setPollTag(pollTagResponse);
          setDateType(getDateType(pollTagResponse));
        } catch (error) {
          console.error(error);
          setShowDataLoadingError(true);
        } finally {
          setDataLoading(false);
        }
      } else {
        try {
          setShowDataLoadingError(false);
          setDataLoading(true);
          const subUsersData = await SubUserApi.getSubUsers();
          const sectorsData = await SectorApi.fetchSectors();
          const pollTemplateData = await PollTemplateApi.fetchPollTemplate({ pollTemplateId });
          if (pollTagId) {
            const pollTagData = await PollTagApi.fetchPollTag(pollTagId);
            setPollTag(pollTagData);
            setDateType(getDateType(pollTagData));
          }
          setSubUsers(subUsersData);
          setSectors(sectorsData);
          setSectorsToShow(sectorsData);
          setPollTemplate(pollTemplateData);
        } catch (error) {
          console.error(error);
          setShowDataLoadingError(true);
        } finally {
          setDataLoading(false);
        }
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSectors = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const filteredArray = sectors.filter((sector) => {
        return sector.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setSectorsToShow(filteredArray);
    } else {
      setSectorsToShow(sectors);
    }

    setSectorsNameFilter(keyword);
  };

  let navigate = useNavigate();

  const handleSectorIdsToUseChange = (sectorId: string) => {
    let sectorIds = sectorIdsToUse;
    const index = sectorIds.indexOf(sectorId, 0);
    if (index > -1) {
      sectorIds.splice(index, 1);
    } else {
      sectorIds.push(sectorId);
    }
    setSectorsToShow([...sectorsToShow]);
    setSectorIdsToUse(sectorIds);
  };

  const handleSectorIdsToUseAllAdd = (sectors: SectorModel[]) => {
    let sectorIds = sectorIdsToUse;
    let deleteSectors = true;
    for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++) {
      const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
      if (!(index > -1)) {
        deleteSectors = false;
        sectorIds.push(sectors[sectorIndex].id);
      }
    }
    if (deleteSectors) {
      for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++) {
        const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
        if (index > -1) {
          sectorIds.splice(index, 1);
        }
      }
    }
    setSectorsToShow([...sectorsToShow]);
    setSectorIdsToUse(sectorIds);
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      const submitData = dateType === "month" ? { ...pollTag, dateEnd: null } : pollTag;

      if (!createNew && pollTagId) {
        let pollTagResponse: PollTagModel;
        pollTagResponse = await PollTagApi.updatePollTag(pollTagId, submitData);
        setPollTag(pollTagResponse);
      } else if (createNew && pollTemplate) {
        let pollTagResponse: PollTagModel;
        pollTagResponse = await PollTagApi.createPollTag(submitData);
        createPolls({ sectorIdsToUse, pollTemplate, subUsers, pollTagId: pollTagResponse.id });
        setPollTag(pollTagResponse);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      navigate(-1);
    }
  }

  const renderMonthContent = (month: number, shortMonth: string, longMonth: string, day: Date) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      {dataLoading && <Spinner animation="border" variant="primary" />}
      {showDataLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!dataLoading && !showDataLoadingError && (
        <Form onSubmit={handleSubmit} className={styles.wrapper}>
          {createNew ? <h1>Добавление отчётного периода</h1> : <h1>Редактирование отчётного периода</h1>}

          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Настройки периода</Card.Title>
              <Row>
                <Col md={4}>
                  <Form.Group controlId="date-type" className="mb-3">
                    <Form.Label style={{ display: "block" }}>Тип даты</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        checked={dateType === "month"}
                        type="radio"
                        name="dateType"
                        id="dateType-month"
                        label="Месяц"
                        onChange={() => {
                          setDateType("month");
                          setPollTag((prev) => {
                            const { dateEnd, ...rest } = prev;
                            return rest;
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        checked={dateType === "range"}
                        type="radio"
                        name="dateType"
                        id="dateType-range"
                        label="Период"
                        onChange={() => {
                          setDateType("range");
                          setPollTag((prev) => ({
                            ...prev,
                            date: new Date().toDateString(),
                            dateEnd: new Date().toDateString(),
                          }));
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col md={8}>
                  {dateType === "month" ? (
                    <Form.Group className="mb-3" controlId={`poll-title`}>
                      <Form.Label style={{ display: "block" }}>Месяц отчётного периода</Form.Label>
                      <DatePicker
                        selected={new Date(pollTag.date)}
                        renderMonthContent={renderMonthContent}
                        showMonthYearPicker
                        onChange={(date) => {
                          if (date) setPollTag((prev) => ({ ...prev, date: date.toDateString() }));
                        }}
                        dateFormat="MM/yyyy"
                        locale="ru"
                        className="form-control"
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="mb-3" controlId={`poll-date-range`}>
                      <Form.Label style={{ display: "block" }}>Период отчётного периода</Form.Label>
                      <div className="d-flex gap-2 align-items-center">
                        <DatePicker
                          selected={new Date(pollTag.date)}
                          onChange={(date) => {
                            if (date) setPollTag((prev) => ({ ...prev, date: date.toDateString() }));
                          }}
                          selectsStart
                          startDate={new Date(pollTag.date)}
                          endDate={pollTag.dateEnd ? new Date(pollTag.dateEnd) : undefined}
                          dateFormat="dd.MM.yyyy"
                          locale="ru"
                          className="form-control"
                        />
                        <span>—</span>
                        <DatePicker
                          selected={pollTag.dateEnd ? new Date(pollTag.dateEnd) : undefined}
                          onChange={(date) => {
                            if (date) setPollTag((prev) => ({ ...prev, dateEnd: date.toDateString() }));
                          }}
                          selectsEnd
                          startDate={new Date(pollTag.date)}
                          endDate={pollTag.dateEnd ? new Date(pollTag.dateEnd) : undefined}
                          minDate={new Date(pollTag.date)}
                          dateFormat="dd.MM.yyyy"
                          locale="ru"
                          className="form-control"
                        />
                      </div>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {createNew && (
            <Form.Group className="mb-4" controlId={`sectors`}>
              <Form.Label>Субъекты РФ</Form.Label>
              <DropdownButton id={`sectors-dropdown`} title="Выбрать субъекты" autoClose="outside">
                <div className={styles.dropdownSearch}>
                  <input
                    type="search"
                    value={sectorNameFilter}
                    onChange={filterSectors}
                    placeholder="Поиск по названию"
                  />
                  <Button onClick={() => handleSectorIdsToUseAllAdd(sectorsToShow)}>Выбрать все</Button>
                </div>
                <div className={cn("custom-scrollbar", styles.dropdownItems)}>
                  {sectorsToShow.map((sector) => (
                    <Dropdown.Item
                      key={sector.id}
                      onClick={() => handleSectorIdsToUseChange(sector.id)}
                      className={styles.subUserItem}
                    >
                      {sector.name}
                      {sectorIdsToUse.includes(sector.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </Form.Group>
          )}

          <div className={`${stylesUtils.width100} ${stylesUtils.flexRight}`}>
            <Button type="submit" className="me-2">
              {createNew ? "Создать" : "Сохранить"}
            </Button>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Отмена
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CreateUpdatePollTagPageView;
