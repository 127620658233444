export default function translateDate(date: string, dateEnd?: string | null) {
  if (dateEnd) {
    return (
      new Date(date).toLocaleString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric" }) +
      " - " +
      new Date(dateEnd).toLocaleString("ru-RU", { day: "2-digit", month: "2-digit", year: "numeric" })
    );
  }

  return new Date(date).toLocaleString("ru-RU", { month: "long", year: "numeric" });
}
