import authToken from "../../Utils/authToken";
import { fetchData } from "../../Utils/fetchData";
import { MapSectorInfo } from "../models/map_sector";
import { SectorDataRF } from "../models/sector_data_rf";

export async function fetchMapSectorsInfo(): Promise<MapSectorInfo[]> {
  const response = await fetchData("/sector/map-info", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

export async function fetchMapSectorsDataRF(): Promise<SectorDataRF[]> {
  const response = await fetchData("/sector/map-data-rf", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}
