import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner, Dropdown } from "react-bootstrap";
import { SlSettings } from "react-icons/sl";
import cn from "classnames";

import { useFetchMapSectorsInfo, useFetchMapSettings, useUpdateMapSettings } from "../../../hooks/useQuery";
import { MapOfSubjectsView } from "./MapOfSubjectsView";
import { MapSectorsDataRFView } from "./MapSectorsDataRFView";
import { MapSettingsCreate, MapSettingsIndicatorType } from "../../../services/models/map_settings";
import styles from "./MapOfSubjectsView.module.css";

export const MapOfSubjectsWrapper = () => {
  const { mapSectorsInfoData, mapSectorsInfoIsLoading, mapSectorsInfoError } = useFetchMapSectorsInfo();
  const { mapSettingsData, mapSettingsError, mapSettingsIsLoading } = useFetchMapSettings();
  const [indicatorType, setIndicatorType] = useState<MapSettingsIndicatorType | null>(null);
  const { updateMapSettings } = useUpdateMapSettings(true);

  useEffect(() => {
    if (mapSettingsData) {
      setIndicatorType(mapSettingsData.indicatorType);
    }
  }, [mapSettingsData]);

  const handleUpdateIndicatorType = (indicatorType: MapSettingsIndicatorType) => {
    const updatedValue: MapSettingsCreate = {
      tagId: mapSettingsData?.tag?.id || "",
      moduleId: mapSettingsData?.module?.id || "",
      valueId: mapSettingsData?.value?.id || "",
      colors: mapSettingsData?.colors || [],
      indicatorType,
      indicatorDataRF: mapSettingsData?.indicatorDataRF || "surgicalBedsForChildren",
    };
    updateMapSettings(updatedValue);
    setIndicatorType(indicatorType);
  };

  return (
    <>
      {(mapSectorsInfoIsLoading || mapSettingsIsLoading) && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {(mapSectorsInfoError || mapSettingsError) && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>Ошибка загрузки данных. Попробуйте обновить страницу</p>
        </div>
      )}

      {mapSectorsInfoData && (
        <div className="position-relative">
          <Dropdown align="end">
            <Dropdown.Toggle variant="link" className={cn("p-0", styles.settings)} id="map-settings-dropdown">
              <SlSettings size={24} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => handleUpdateIndicatorType("data_rf")}
                className={cn({ "text-primary": indicatorType === "data_rf" })}
              >
                Данные по РФ
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleUpdateIndicatorType("data_user")}
                className={cn({ "text-primary": indicatorType === "data_user" })}
              >
                Показатели пользователя
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/administrator/cabinet/map_settings">
                Настройки карты
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {mapSettingsData && (
            <>
              {indicatorType === "data_rf" && (
                <MapSectorsDataRFView sectors={mapSectorsInfoData} mapSettingsData={mapSettingsData} />
              )}

              {indicatorType === "data_user" && (
                <MapOfSubjectsView sectors={mapSectorsInfoData} mapSettingsData={mapSettingsData} />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
