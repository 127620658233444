import { Button, Col, Row, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import * as PollTagApi from "../../../services/network/poll_tag-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PollForSubUser from "../../../components/PollForSubUser";
import { Sector as SectorModel } from "../../../services/models/sector";
import { PollTag as PollTagModel } from "../../../services/models/poll_tag";
import translateDate from "../../../Utils/translateDate";
import { SubUser } from "../../../services/models/sub_user";

interface PollsPageViewProps {
  user: SubUser;
}

const PollsPageView = ({ user }: PollsPageViewProps) => {
  interface PollFull {
    poll: PollModel;
    sector: SectorModel;
    tag: PollTagModel;
  }
  const [pollsWithSectors, setPollsWithSectors] = useState<PollFull[]>([]);
  const [pollsLoading, setPollsLoading] = useState(true);
  const [showPollsLoadingError, setShowPollsLoadingError] = useState(false);

  useEffect(() => {
    async function loadPolls() {
      try {
        setShowPollsLoadingError(false);
        setPollsLoading(true);
        const pollsWithSectorsData: PollFull[] = [];
        const pollsData = await PollApi.fetchPollsForSubUser();
        for (let i = 0; i < pollsData.length; i++) {
          const sectorData = await SectorApi.fetchSector(pollsData[i].sector);
          const pollTagData = await PollTagApi.fetchPollTag(pollsData[i].tag);
          pollsWithSectorsData.push({ poll: pollsData[i], sector: sectorData, tag: pollTagData });
        }
        setPollsWithSectors(pollsWithSectorsData);
      } catch (error) {
        console.error(error);
        setShowPollsLoadingError(true);
      } finally {
        setPollsLoading(false);
      }
    }
    loadPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let navigate = useNavigate();

  const goToFillPoll = (poll: PollModel) => {
    let path = `${poll.id}`;
    navigate(path);
  };

  const finishPoll = async (poll: PollModel) => {
    try {
      const updatedPoll = await PollApi.fetchPollFinishForSubUser({ pollId: poll.id });
      setPollsWithSectors(
        pollsWithSectors.map((existingPollWithSector) =>
          existingPollWithSector.poll.id === updatedPoll.id
            ? { poll: updatedPoll, sector: existingPollWithSector.sector, tag: existingPollWithSector.tag }
            : existingPollWithSector
        )
      );
    } catch (error) {
      console.error(error);
      setShowPollsLoadingError(true);
    }
  };

  const pollsGrid = (
    <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
      {pollsWithSectors.map((pollWithSector) => (
        <Col key={pollWithSector.poll.id}>
          <PollForSubUser
            poll={pollWithSector.poll}
            user={user}
            sectorName={pollWithSector.sector.name}
            tagName={translateDate(pollWithSector.tag.date, pollWithSector.tag.dateEnd)}
            onFillPollClicked={goToFillPoll}
            onFinishPollClicked={finishPoll}
            className={styles.project}
          />
        </Col>
      ))}
    </Row>
  );
  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      <h1>Формы сбора данных</h1>
      {pollsLoading && <Spinner animation="border" variant="primary" />}
      {showPollsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!pollsLoading && !showPollsLoadingError && (
        <>{pollsWithSectors.length > 0 ? pollsGrid : <p>Формы сбора данных ещё не добавлены</p>}</>
      )}
    </>
  );
};

export default PollsPageView;
