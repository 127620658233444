import { useEffect, useState } from "react";
import { Button, ListGroup } from "react-bootstrap";

import {
  MapSettings,
  MapSettingsCreate,
  MapSettingsIndicatorDataRF,
  mapSettingsIndicatorDataRFTitles,
} from "../../../services/models/map_settings";
import { useUpdateMapSettings } from "../../../hooks/useQuery";

interface Props {
  mapSettingsData?: MapSettings | null;
}

export const MapSettingsSelectingIndicatorRF = ({ mapSettingsData }: Props) => {
  const { updateMapSettings } = useUpdateMapSettings();
  const [selectedValue, setSelectedValue] = useState<MapSettingsIndicatorDataRF | null>(null);

  useEffect(() => {
    if (mapSettingsData?.indicatorDataRF) {
      setSelectedValue(mapSettingsData.indicatorDataRF);
    }
  }, [mapSettingsData]);

  const handleUpdateIndicatorDataRF = () => {
    if (selectedValue && selectedValue !== mapSettingsData?.indicatorDataRF) {
      const updatedValue: MapSettingsCreate = {
        tagId: mapSettingsData?.tag?.id || "",
        moduleId: mapSettingsData?.module?.id || "",
        valueId: mapSettingsData?.value?.id || "",
        colors: mapSettingsData?.colors || [],
        indicatorType: mapSettingsData?.indicatorType || "data_rf",
        indicatorDataRF: selectedValue,
      };
      updateMapSettings(updatedValue);
    }
  };

  return (
    <div>
      <h5 className="text-center">Выбранный показатель</h5>

      <ListGroup style={{ width: 600 }}>
        {Object.entries(mapSettingsIndicatorDataRFTitles).map(([key, value]) => (
          <ListGroup.Item
            key={key}
            action
            variant={selectedValue === key ? "primary" : "secondary"}
            onClick={() => setSelectedValue(key as MapSettingsIndicatorDataRF)}
          >
            {value}
          </ListGroup.Item>
        ))}
      </ListGroup>

      <div className="d-flex justify-content-center gap-2 mt-3">
        <Button
          onClick={handleUpdateIndicatorDataRF}
          variant="success"
          style={{ width: 208 }}
          disabled={selectedValue === mapSettingsData?.indicatorDataRF}
        >
          Сохранить
        </Button>
      </div>
    </div>
  );
};
