import { useQuery } from "@tanstack/react-query";

import { fetchMapSectorsDataRF } from "../../services/network/map_sector-api";

export function useFetchMapSectorsDataRF() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/sectors/map-data-rf"],
    queryFn: () => fetchMapSectorsDataRF(),
  });

  return {
    mapSectorsDataRF: data,
    mapSectorsDataRFError: error,
    mapSectorsDataRFIsLoading: isLoading,
  };
}
