import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { updateMapSettings } from "../../services/network/map_settings-api";
import { MapSettingsCreate } from "../../services/models/map_settings";

export function useUpdateMapSettings(isNotShowUpdatingMessage?: boolean) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ["/map_settings/update"],
    mutationFn: (mapSettings: MapSettingsCreate) => updateMapSettings(mapSettings),
    onError() {
      toast.error("Ошибка обновления настроек показателей");
    },
    onSuccess() {
      if (!isNotShowUpdatingMessage) {
        toast.success("Настройки показателей обновлены");
      }

      queryClient.invalidateQueries({
        queryKey: ["/map_settings"],
      });
    },
  });

  return {
    updateMapSettings: mutate,
  };
}
