import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { MapSectorInfo, MapSectorInfoValue } from "../../../services/models/map_sector";
import { Poll } from "../../../services/models/poll";
import { fetchPolls } from "../../../services/network/poll-api";
import { MapSettings, MapSettingsColor } from "../../../services/models/map_settings";
import { CELL_SIZE, GAP, GRID_COLUMNS, getElementAt, getSectorColor, isDarkColor, mapGrid } from "./utils";
import styles from "./MapOfSubjectsView.module.css";

interface Props {
  sectors: MapSectorInfo[];
  mapSettingsData: MapSettings;
}

export const MapOfSubjectsView = ({ sectors, mapSettingsData }: Props) => {
  const [polls, setPolls] = useState<Poll[]>([]);
  const [sectorsValues, setSectorsValues] = useState<MapSectorInfoValue[]>([]);
  const [colors, setColors] = useState<MapSettingsColor[]>([]);

  useEffect(() => {
    if (mapSettingsData?.tag?.id) {
      (async () => {
        const pollsRequest = await fetchPolls({ tag_id: mapSettingsData.tag.id });
        setPolls(pollsRequest);
      })();
    }
  }, [mapSettingsData]);

  useEffect(() => {
    if (mapSettingsData && sectors?.length && polls.length) {
      const { module, value, colors } = mapSettingsData;

      if (value?.id && module?.id) {
        const result: MapSectorInfoValue[] = [];

        for (const element of sectors) {
          const findedPoll = polls.find(({ sector }) => sector === element.sectorId);

          if (findedPoll) {
            const findedModule = findedPoll.modules.find(({ id }) => id === module.id);
            const findedValue = findedModule?.values.find(({ id }) => id === value.id);

            if (findedValue && findedValue.value !== undefined) {
              result.push({
                sectorId: element.sectorId,
                value: findedValue.value,
                title: findedValue.title,
              });
            }
          }
        }

        setSectorsValues(result);
      }

      if (colors.length) {
        setColors(colors);
      }
    }
  }, [sectors, polls, mapSettingsData]);

  const renderTooltip = (tooltip: string) => (props: React.ComponentPropsWithoutRef<typeof Tooltip>) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    );

  return (
    <div
      className={styles.wrapper}
      style={{
        width: `${GRID_COLUMNS * (CELL_SIZE + GAP)}px`,
        gridTemplateColumns: `repeat(${GRID_COLUMNS}, ${CELL_SIZE}px)`,
        gap: GAP,
      }}
    >
      {mapGrid.map(({ x, y }, index) => {
        const element = getElementAt(sectors, x, y);
        const valueInfo = sectorsValues.find(({ sectorId }) => sectorId === element?.sectorId);
        const isValueExists = valueInfo?.value !== "-" && valueInfo?.value !== "";
        const color =
          valueInfo?.value === undefined
            ? "#ededed"
            : isValueExists
            ? getSectorColor(colors, Number(valueInfo.value))
            : "#fff";
        const tooltip = valueInfo && isValueExists ? `${valueInfo.title}: ${valueInfo.value}` : "Нет данных";

        return (
          <div
            key={index}
            className={styles.mapItem}
            style={{
              width: CELL_SIZE,
              height: CELL_SIZE,
              border: element ? "1px solid #aaa" : "none",
            }}
          >
            {element && (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={renderTooltip(tooltip)}>
                <Link
                  to={`/administrator/cabinet/sectors/${element.sectorId}`}
                  className={styles.mapItemLink}
                  style={{
                    backgroundColor: element ? color : "transparent",
                    color: isDarkColor(color) ? "#ddd" : "#222",
                  }}
                >
                  <strong>{element.shortName}</strong>
                  {valueInfo?.value === undefined ? (
                    <span style={{ visibility: "hidden" }}>-</span>
                  ) : (
                    <span>{valueInfo.value || "-"}</span>
                  )}
                </Link>
              </OverlayTrigger>
            )}
          </div>
        );
      })}
    </div>
  );
};
