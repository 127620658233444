import { SectorDataRF } from "./sector_data_rf";

export interface MapSettingsColor {
  minValue: number;
  maxValue: number;
  color: string;
}

export type MapSettingsIndicatorType = "data_user" | "data_rf";
export type MapSettingsIndicatorDataRF = keyof SectorDataRF["data"];

export interface MapSettingsCreate {
  tagId: string;
  moduleId: string;
  valueId: string;
  colors: MapSettingsColor[];
  indicatorType: MapSettingsIndicatorType;
  indicatorDataRF: MapSettingsIndicatorDataRF;
}

export interface MapSettings {
  tag: {
    id: string;
    title: string;
    date: string;
    dateEnd?: string | null;
  };
  module: {
    id: string;
    title: string;
  };
  value: {
    id: string;
    title: string;
  };
  colors: MapSettingsColor[];
  indicatorType: MapSettingsIndicatorType;
  indicatorDataRF: MapSettingsIndicatorDataRF;
}

export interface MapSettingsIndicatorUpdate {
  tagId: string;
  moduleId: string;
  valueId: string;
}

export const mapSettingsIndicatorDataRFTitles: Record<MapSettingsIndicatorDataRF, string> = {
  surgicalBedsForChildren: "Число коек хирургических для детей",
  bedDaysSpentPatients: "Проведено пациентами койко-дней",
  bedWork: "Работа койки",
};
