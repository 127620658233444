import { useEffect, useState } from "react";
import { Button, ListGroup, Spinner } from "react-bootstrap";

import { useFetchPollTemplates } from "../../../hooks/useQuery";
import { PollTemplate, PollTemplateValue } from "../../../services/models/poll_template";
import { fetchPollTags } from "../../../services/network/poll_tag-api";
import { PollTag } from "../../../services/models/poll_tag";
import translateDate from "../../../Utils/translateDate";
import { MapSettingsIndicatorUpdate } from "../../../services/models/map_settings";

interface Props {
  onUpdateMapSettings: (updatedValue: MapSettingsIndicatorUpdate) => void;
  onClose: () => void;
}

export const MapSettingsSelectingIndicator = ({ onUpdateMapSettings, onClose }: Props) => {
  const { pollTemplatesData, pollTemplatesError, pollTemplatesIsLoading } = useFetchPollTemplates();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pollTemplate, setPollTemplate] = useState<PollTemplate | null>(null);
  const [tags, setTags] = useState<PollTag[]>([]);
  const [values, setValues] = useState<PollTemplateValue[]>([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [selectedModule, setSelectedModule] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    if (pollTemplate) {
      (async () => {
        setIsLoading(true);
        try {
          const tagsData = await fetchPollTags({ poll_template_id: pollTemplate.id });

          if (tagsData) {
            setTags(tagsData);
          } else {
            setIsError(true);
          }
        } catch (error) {
          console.error(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [pollTemplate]);

  const handleBack = () => {
    if (selectedValue) {
      setSelectedValue(null);
    } else if (selectedModule) {
      setSelectedModule(null);
      setValues([]);
    } else if (selectedTag) {
      setSelectedTag(null);
    } else {
      setPollTemplate(null);
    }
  };

  const handleSelectModule = (moduleId: string) => {
    setSelectedModule(moduleId);
    setValues(pollTemplate?.modules.find((module) => module.id === moduleId)?.values || []);
  };

  const handleSave = () => {
    if (selectedTag && selectedModule && selectedValue) {
      onUpdateMapSettings({
        tagId: selectedTag,
        moduleId: selectedModule,
        valueId: selectedValue,
      });
    }
  };

  return (
    <div>
      <h5 className="text-center">Выбор показателя</h5>

      {(pollTemplatesIsLoading || isLoading) && (
        <Spinner animation="border" variant="primary" className="d-block mx-auto" />
      )}
      {(pollTemplatesError || isError) && <p className="text-center">Something went wrong. Please refresh the page.</p>}

      <div style={{ minHeight: 300 }}>
        {!pollTemplate && (
          <ListGroup style={{ width: 600 }}>
            {pollTemplatesData?.map((pollTemplate) => (
              <ListGroup.Item
                key={pollTemplate.id}
                action
                variant="primary"
                onClick={() => setPollTemplate(pollTemplate)}
              >
                {pollTemplate.title}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        {pollTemplate && !selectedTag && (
          <>
            {tags.length ? (
              <ListGroup style={{ width: 600 }}>
                {tags.map((tag) => (
                  <ListGroup.Item key={tag.id} action variant="primary" onClick={() => setSelectedTag(tag.id)}>
                    {pollTemplate.title} - {translateDate(tag.date, tag.dateEnd)}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p className="text-center py-3">Отчётные периоды ещё не добавлены</p>
            )}
          </>
        )}

        {pollTemplate && selectedTag && !selectedModule && (
          <>
            {pollTemplate.modules.length ? (
              <ListGroup style={{ width: 600 }}>
                {pollTemplate.modules.map((module) => (
                  <ListGroup.Item
                    key={module.id}
                    action
                    variant="primary"
                    onClick={() => handleSelectModule(module.id)}
                  >
                    {module.title || "Название не указано"}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p className="text-center py-3">Модули ещё не добавлены</p>
            )}
          </>
        )}

        {pollTemplate && selectedTag && selectedModule && (
          <>
            {values.length ? (
              <ListGroup style={{ width: 600 }}>
                {values.map((value) => (
                  <ListGroup.Item key={value.id} action variant="primary" onClick={() => setSelectedValue(value.id)}>
                    {value.title || "Название не указано"}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <p className="text-center py-3">Значения ещё не добавлены</p>
            )}
          </>
        )}
      </div>

      <div className="d-flex justify-content-center gap-2 mt-3">
        <Button onClick={onClose} variant="secondary" style={{ width: 100 }}>
          Отменить
        </Button>

        {pollTemplate && (
          <Button onClick={handleBack} style={{ width: 100 }}>
            Назад
          </Button>
        )}
      </div>

      {selectedValue && (
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={handleSave} variant="success" style={{ width: 208 }}>
            Сохранить
          </Button>
        </div>
      )}
    </div>
  );
};
