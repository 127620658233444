import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { MapSectorInfo, MapSectorInfoValue } from "../../../services/models/map_sector";
import { MapSettings, mapSettingsIndicatorDataRFTitles } from "../../../services/models/map_settings";
import { useFetchMapSectorsDataRF } from "../../../hooks/useQuery/useFetchMapSectorsDataRF";
import { CELL_SIZE, GAP, GRID_COLUMNS, getElementAt, getSectorColorForDataRF, isDarkColor, mapGrid } from "./utils";
import styles from "./MapOfSubjectsView.module.css";

interface Props {
  sectors: MapSectorInfo[];
  mapSettingsData: MapSettings;
}

export const MapSectorsDataRFView = ({ sectors, mapSettingsData }: Props) => {
  const { mapSectorsDataRF } = useFetchMapSectorsDataRF();
  const [sectorsValues, setSectorsValues] = useState<MapSectorInfoValue[]>([]);
  const [avgValue, setAvgValue] = useState(0);

  useEffect(() => {
    if (mapSectorsDataRF && mapSettingsData && sectors?.length) {
      const result: MapSectorInfoValue[] = [];
      const filteredData = mapSectorsDataRF.map(({ data }) => data[mapSettingsData.indicatorDataRF]).filter(Boolean);
      const avg = filteredData.reduce((a, b) => a + b, 0) / filteredData.length;

      for (const element of sectors) {
        const findedData = mapSectorsDataRF.find(({ sectorId }) => sectorId === element.sectorId);

        if (findedData && findedData.data[mapSettingsData.indicatorDataRF]) {
          result.push({
            sectorId: element.sectorId,
            value: findedData.data[mapSettingsData.indicatorDataRF].toString(),
            title: mapSettingsIndicatorDataRFTitles[mapSettingsData.indicatorDataRF],
          });
        }
      }

      setAvgValue(avg);
      setSectorsValues(result);
    }
  }, [sectors, mapSettingsData, mapSectorsDataRF]);

  const renderTooltip = (tooltip: string) => (props: React.ComponentPropsWithoutRef<typeof Tooltip>) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {tooltip}
      </Tooltip>
    );

  return (
    <div
      className={styles.wrapper}
      style={{
        width: `${GRID_COLUMNS * (CELL_SIZE + GAP)}px`,
        gridTemplateColumns: `repeat(${GRID_COLUMNS}, ${CELL_SIZE}px)`,
        gap: GAP,
      }}
    >
      {mapGrid.map(({ x, y }, index) => {
        const element = getElementAt(sectors, x, y);
        const valueInfo = sectorsValues.find(({ sectorId }) => sectorId === element?.sectorId);
        const isValueExists = valueInfo?.value !== "-" && valueInfo?.value !== "";
        const color =
          valueInfo?.value === undefined
            ? "#ededed"
            : isValueExists
            ? getSectorColorForDataRF(Number(valueInfo.value), avgValue)
            : "#fff";
        const tooltip = valueInfo && isValueExists ? `${valueInfo.title}: ${valueInfo.value}` : "Нет данных";

        return (
          <div
            key={index}
            className={styles.mapItem}
            style={{
              width: CELL_SIZE,
              height: CELL_SIZE,
              border: element ? "1px solid #aaa" : "none",
            }}
          >
            {element && (
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 250 }} overlay={renderTooltip(tooltip)}>
                <Link
                  to={`/administrator/cabinet/sectors/${element.sectorId}`}
                  className={styles.mapItemLink}
                  style={{
                    backgroundColor: element ? color : "transparent",
                    color: isDarkColor(color) ? "#ddd" : "#222",
                  }}
                >
                  <strong>{element.shortName}</strong>
                  {valueInfo?.value === undefined ? (
                    <span style={{ visibility: "hidden" }}>-</span>
                  ) : (
                    <span>{valueInfo.value || "-"}</span>
                  )}
                </Link>
              </OverlayTrigger>
            )}
          </div>
        );
      })}
    </div>
  );
};
