import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "./assets/styles/App.module.css";

import NavBar from "./parts/NavBar/NavBar";

import { AdministratorProtectedRoute, SubUserProtectedRoute } from "./components/ProtectedRoute";

import { Administrator } from "./services/models/administrator";
import { SubUser } from "./services/models/sub_user";

import * as AdministratorApi from "./services/network/administrator-api";
import * as SubUserApi from "./services/network/sub_user-api";

import AuthPage from "./pages/Lending/AuthPage";
import AdministratorAuthPage from "./pages/Admin/Auth/AdminAuthPage";
import AdministratorCabinetPage from "./pages/Admin/Home/AdminCabinetPage";
import FillPollPage from "./pages/Admin/Poll/FillPollPage";
import PollResultPage from "./pages/Admin/Poll/PollResultPage";
import PollsPage from "./pages/Admin/Poll/PollsPage";
import SubUserAuthPage from "./pages/SubUser/Auth/SubUserAuthPage";
import SubUserCabinetPage from "./pages/SubUser/Home/SubUserCabinetPage";
import SubUsersPage from "./pages/Admin/SubUser/SubUsersPage";
import RolesPage from "./pages/Admin/Role/RolesPage";
import SubUserPollsPage from "./pages/SubUser/Poll/PollsPage";
import SubUserFillPollPage from "./pages/SubUser/Poll/FillPollPage";
import StatisticsPage from "./pages/Admin/Statistics/StatisticsPage";
import PollTemplatesPage from "./pages/Admin/PollTemplate/PollTemplatesPage";
import CreateUpdatePollTemplatePage from "./pages/Admin/PollTemplate/CreateUpdatePollTemplatePage";
import { SectorsPage } from "./pages/Admin/Sectors/SectorsPage";
import { SectorPage } from "./pages/Admin/Sector/SectorPage";
import UpdatePollPage from "./pages/Admin/Poll/UpdatePollPage";
import SectorStatisticsPage from "./pages/Admin/Statistics/SectorStatisticsPage";
import PollTagsPage from "./pages/Admin/PollTag/PollTagsPage";
import CreateUpdatePollTagPage from "./pages/Admin/PollTag/CreateUpdatePollTagPage";
import AddPollsPage from "./pages/Admin/Poll/AddPollsPage";
// import PollTemplatePage from "./pages/Admin/PollTemplate/PollTemplatePage";
import DistrictsPage from "./pages/Admin/District/DistrictsPage";
import DistrictStatisticsPage from "./pages/Admin/Statistics/DistrictStatisticsPage";
import RecommendationSectorsPage from "./pages/Admin/RecommendationSector/RecommendationSectorsPage";
import RecommendationsPage from "./pages/Admin/Recommendation/RecommendationsPage";
import RecommendationInfoPage from "./pages/Admin/RecommendationInfo/RecommendationInfoPage";
import SubUserRecommendationsPage from "./pages/SubUser/Recommendation/RecommendationsPage";
import SubUserRecommendationInfoPage from "./pages/SubUser/RecommendationInfo/RecommendationInfoPage";
import MapSettingsPage from "./pages/Admin/MapSettings/MapSettingsPage";

function App() {
  const [loggedInUser, setLoggedInUser] = useState<Administrator | SubUser | null>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [showUserLoadingError, setShowUserLoadingError] = useState(false);

  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        const administrator = await AdministratorApi.getLoggedAdministrator();
        setLoggedInUser(administrator);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== "UnauthorizedError") {
            console.error(error);
            //setShowUserLoadingError(true);
          }
        }
      }
      try {
        const subUser = await SubUserApi.getLoggedSubUser();
        setLoggedInUser(subUser);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== "UnauthorizedError") {
            console.error(error);
            //setShowUserLoadingError(true);
          }
        }
      } finally {
        setUserLoading(false);
      }
    }
    fetchLoggedInUser();
  }, []);

  return (
    <BrowserRouter>
      {userLoading && <Spinner animation="border" variant="primary" />}
      {showUserLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!userLoading && !showUserLoadingError && (
        <div>
          <NavBar loggedInUser={loggedInUser} onLogoutSuccessful={() => setLoggedInUser(null)} />
          <Container className={styles.pageContainer}>
            <Routes>
              <Route path="/" element={<AuthPage />} />
              <Route
                path="/administrator"
                element={
                  <AdministratorAuthPage
                    onLoginSuccessful={(administrator) => {
                      setLoggedInUser(administrator);
                    }}
                  />
                }
              />
              <Route
                path="/administrator/cabinet"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <AdministratorCabinetPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <PollTemplatesPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/create"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTemplatePage createNew={true} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/update"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTemplatePage createNew={false} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/copy"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTemplatePage createNew={true} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <PollTagsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/map_settings"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <MapSettingsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/update"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTemplatePage createNew={false} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/create"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTagPage createNew={true} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <PollsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/copy"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTagPage createNew={true} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/update"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <CreateUpdatePollTagPage createNew={false} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/add"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <AddPollsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/:pollId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <UpdatePollPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/:pollId/fill"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <FillPollPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/poll_templates/:pollTemplateId/:pollTagId/:pollId/result"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <PollResultPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/recommendations"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <RecommendationSectorsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/recommendations/:sectorId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <RecommendationsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/recommendations/:sectorId/:recommendationId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <RecommendationInfoPage user={loggedInUser} />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/statistics"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <StatisticsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/statistics/:pollTemplateId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <DistrictStatisticsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/statistics/:pollTemplateId/:districtId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorStatisticsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/districts"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <DistrictsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sectors"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorsPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sectors/:sectorId"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorPage type="main" />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sectors/:sectorId/polls"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorPage type="polls" />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sectors/:sectorId/recommendations"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorPage type="recommendations" />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sectors/:sectorId/users"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SectorPage type="users" />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/roles"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <RolesPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/administrator/cabinet/sub_users"
                element={
                  <AdministratorProtectedRoute user={loggedInUser}>
                    <SubUsersPage />
                  </AdministratorProtectedRoute>
                }
              />
              <Route
                path="/user"
                element={
                  <SubUserAuthPage
                    onLoginSuccessful={(subUser) => {
                      setLoggedInUser(subUser);
                    }}
                  />
                }
              />
              <Route
                path="/user/cabinet"
                element={
                  <SubUserProtectedRoute user={loggedInUser}>
                    <SubUserCabinetPage />
                  </SubUserProtectedRoute>
                }
              />
              <Route
                path="/user/cabinet/polls"
                element={
                  <SubUserProtectedRoute user={loggedInUser}>
                    <SubUserPollsPage user={loggedInUser} />
                  </SubUserProtectedRoute>
                }
              />
              <Route
                path="/user/cabinet/polls/:pollId"
                element={
                  <SubUserProtectedRoute user={loggedInUser}>
                    <SubUserFillPollPage />
                  </SubUserProtectedRoute>
                }
              />
              <Route
                path="/user/cabinet/recommendations"
                element={
                  <SubUserProtectedRoute user={loggedInUser}>
                    <SubUserRecommendationsPage />
                  </SubUserProtectedRoute>
                }
              />
              <Route
                path="/user/cabinet/recommendations/:recommendationId"
                element={
                  <SubUserProtectedRoute user={loggedInUser}>
                    <SubUserRecommendationInfoPage user={loggedInUser} />
                  </SubUserProtectedRoute>
                }
              />
            </Routes>
          </Container>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={3000}
        limit={3}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
    </BrowserRouter>
  );
}

export default App;
