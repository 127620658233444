import { MapSettingsColor } from "./../../../services/models/map_settings";
import { MapSectorInfo } from "../../../services/models/map_sector";

export const GRID_COLUMNS = 18;
export const CELL_SIZE = 64;
export const GAP = 2;

export const mapGrid = Array.from({ length: GRID_COLUMNS * 11 }, (_, index) => ({
  x: index % GRID_COLUMNS,
  y: Math.floor(index / GRID_COLUMNS),
}));

export const getElementAt = (sectors: MapSectorInfo[], x: number, y: number) =>
  sectors.find(({ coordinates }) => coordinates.x === x && coordinates.y === y);

export const getSectorColor = (colors: MapSettingsColor[], value: number) => {
  const color = colors.find((color) => value >= color.minValue && value <= color.maxValue);

  return color ? color.color : "#fff";
};

export const getSectorColorForDataRF = (value: number, avgValue: number) => {
  if (avgValue === 0) return "#f1e198";

  const diffPercent = ((value - avgValue) / avgValue) * 100;

  if (diffPercent >= -10 && diffPercent <= 10) return "#f1e198";
  if (diffPercent > 10 && diffPercent <= 20) return "#c3f4ab";
  if (diffPercent > 20 && diffPercent <= 40) return "#7ea471";
  if (diffPercent > 40 && diffPercent <= 60) return "#4a6847";
  if (diffPercent > 60) return "#30442e";

  if (diffPercent < -10 && diffPercent >= -20) return "#eec687";
  if (diffPercent < -20 && diffPercent >= -40) return "#d59e58";
  if (diffPercent < -40 && diffPercent >= -60) return "#c46666";
  if (diffPercent < -60) return "#7e3e3e";

  return "#f1e198";
};

export const isDarkColor = (hex: string) => {
  if (!hex) return false;

  hex = hex.replace(/^#/, "");

  // Преобразуем в RGB
  let r, g, b;
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  // Вычисляем яркость (Luma)
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128; // Если меньше 128, считаем цвет тёмным
};
